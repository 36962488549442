import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Maximize Impact Long After Launch
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              Our Post-Launch Enablement Services get your team excited and
              confident about using your new Quickbase applications.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label for="tab1" class="tabs__label mx-auto">
                Immediate, Expert Support
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/4424d90b31ebd2b23997a14238a43e92e3734e0d-6628x4419.jpg"
                      alt="Immediate, Expert Support"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Immediate, Expert Support
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Minimize downtime and keep operations running smoothly
                      with expert support that protects your team’s efficiency
                      and customer relationships.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label for="tab2" class="tabs__label mx-auto">
                Dissolve Performance Roadblocks
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Dissolve Performance Roadblocks
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Overcome adoption hurdles and fix critical performance
                      issues, so your team can confidently use Quickbase to its
                      full potential.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/1b88469439828bb46391ef5d8a89677a05a06a55-4608x3072.jpg"
                      alt="Dissolve Performance Roadblocks"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label for="tab3" class="tabs__label mx-auto">
                Upskill Your Team
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/050a8d1b69153442a52eafcef863f2c07823d0b1-6553x4369.jpg"
                      alt=" Upskill Your Team"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Upskill Your Team</h3>
                    <p className="my-4 text-left-mobile">
                      Provide your team with the training, documentation, and
                      expert support they need to confidently manage and
                      optimize your Quickbase applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Immediate, Expert Support</h3>
                    <p className="my-4 ">
                      Minimize downtime and keep operations running smoothly
                      with expert support that protects your team’s efficiency
                      and customer relationships.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/4424d90b31ebd2b23997a14238a43e92e3734e0d-6628x4419.jpg"
                      alt="Immediate, Expert Support"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Dissolve Performance Roadblocks</h3>
                    <p className="my-4 ">
                      Overcome adoption hurdles and fix critical performance
                      issues, so your team can confidently use Quickbase to its
                      full potential.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/1b88469439828bb46391ef5d8a89677a05a06a55-4608x3072.jpg"
                      alt="Dissolve Performance Roadblocks"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Upskill Your Team</h3>
                    <p className="my-4 ">
                      Provide your team with the training, documentation, and
                      expert support they need to confidently manage and
                      optimize your Quickbase applications.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/050a8d1b69153442a52eafcef863f2c07823d0b1-6553x4369.jpg"
                      alt="Upskill Your Team"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
