import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Banner from "../components/hero/hero-ples.js"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import TeamBanner from "../components/hero/banner-hometeam.js"
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import { useContext } from "react"

import PartnerSwiper from "../components/swiper/swiper-partner.js"

import Testimonials2 from "../components/swiper/testimonial-swiper"

import ItConsulting from "../components/multi-tab/ples-multi-tab.js"

import CaseStudySection from "../components/header/header-casestudy-section"

import Cases from "../components/cases"

import UnlockEnterprise from "../components/unlock-enterprise.js"

class PlesPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Post Launch Enablement | Quandary Consulting Group"
          description="We provide the ongoing support, documentation, and user training your team needs to maximize the business value of your Quickbase applications."
        />

        <PartnerSwiper />
        <ItConsulting />
        <section
          className="faq-wrapper security-audit"
          style={{ padding: "1.5rem 0 4.5rem" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <h2 className="mt-0 text-center">
                  Our Post-Launch Enablement Process
                </h2>
                <Accordion defaultActiveKey="0" key="1">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="1" className="bg-transparent">
                      1. Application Audit (Required)
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          We identify bugs, assess system performance, and
                          prioritize high-impact improvements.
                        </p>
                        <p>
                          This deep dive into your existing tools and
                          infrastructure ensures we address critical issues
                          first—setting the foundation for effective ongoing
                          support.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="2">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="2" className="bg-transparent">
                      2. End-User Documentation and Training
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p>
                          We uncover adoption challenges by gathering user
                          feedback and delivering clear, actionable insights to
                          stakeholders.
                        </p>
                        <p>
                          Additionally, we develop comprehensive visual guides
                          (PPTs, PDFs) covering:
                        </p>
                        <div className="results mb-3">
                          <ul>
                            <li>Step-by-step processes for end users</li>
                            <li>Navigation and issue escalation workflows</li>
                            <li>
                              Supporting documentation for seamless onboarding
                              and long-term success.
                            </li>
                          </ul>
                        </div>
                        <p>
                          To improve adoption, our consultants provide hands-on
                          training, office hours, and interactive walkthroughs.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="3">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="3" className="bg-transparent">
                      3. Admin User Documentation and Training
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p>
                          Equip your admins with the knowledge to manage
                          Quickbase confidently.
                        </p>
                        <p>
                          Our Live Training Sessions and Office Hours, led by
                          our consultants, show your admins how to use
                          applications safely.
                        </p>
                        <p>
                          We also provide your team with visual guides and
                          documentation, ensuring they enforce permissions, run
                          essential tool maintenance, and properly support
                          end-users.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="4">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="4" className="bg-transparent">
                      4. Advanced Application Training
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <p>
                          Empower your IT team with in-depth technical training
                          on Quickbase functionality, maintenance, and
                          integrations.
                        </p>
                        <p>
                          This includes assistance with data governance, access
                          management, and system upkeep—ensuring your team has
                          the skills to manage and optimize your applications
                          effectively.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="5">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="5" className="bg-transparent">
                      5. Advanced Technical Design Documentation
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <p>
                          Need technical design documentation, including
                          comments within code pages, to remain compliant with
                          your IT policies? App structure outlining? Data
                          movement? Automation?
                        </p>
                        <p>
                          Our team provides the structured insights your IT team
                          needs to manage, maintain, and optimize your Quickbase
                          applications with confidence.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="6">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="6" className="bg-transparent">
                      6. Tech Debt Assessment and Clean-Up
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        <p>
                          Eliminate inefficiencies and streamline your Quickbase
                          environment.
                        </p>
                        <p>
                          We identify and resolve quick-fix tech debt after
                          remediation suggestions. Remove deprecated or
                          unconnected tables, fields, reports, dashboards,
                          homepages, forms, and roles. And more.
                        </p>
                        <p>Ensuring a cleaner, more efficient system.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="7">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="7" className="bg-transparent">
                      7. Rollout and Go-Live Support
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        <p>
                          Ensure a smooth launch with hands-on end-user support.
                        </p>
                        <p>
                          We assist with UI navigation, access management, and
                          troubleshooting during new application and feature
                          rollouts—minimizing disruptions and maximizing
                          adoption.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0" key="8">
                  <Card className="accordion-body">
                    <ContextAwareToggle eventKey="8" className="bg-transparent">
                      8. Legacy Data Import
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        <p>
                          Seamlessly migrate clean legacy data into your new
                          Quickbase applications with expert support, using
                          native Quickbase import functionality for a smooth
                          transition.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <Testimonials2 />
        <CaseStudySection />
        <CaseComponent dataSrc={this.props.data.allSanityCaseStudy} />
        <TeamBanner />
        <UnlockEnterprise />
        <section
          className="bg-case-study slogan-section"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <div>
                  <h2 className="text-white">
                    Post-Launch Enablement For Your Quickbase Apps: Resolve
                    Issues, Improve Adoption, and Scale Your Back-Office
                  </h2>
                  {/* <p className="text-white">
                  Our 10-point security audit will show you how secure your
                  applications are. <br /> Protect your data. Protect your
                  investment. Protect your organization.
                </p> */}

                  <Link
                    to="/contact/"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default PlesPage

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

export const plesPageQuery = graphql`
  query plesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
